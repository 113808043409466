html {
  font-size: 62.5%;
}

body {
  color: #ddd;
  background-color: #333;
  font-family: Raleway, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.6;
}

a {
  color: #fff;
}

a:hover {
  color: #eee;
}

.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
  color: #ccc;
  text-align: center;
  letter-spacing: .1rem;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #0000;
  border: 1px solid #bbb;
  border-radius: 4px;
  height: 38px;
  padding: 0 30px;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  text-decoration: none;
  display: inline-block;
}

.button:hover, button:hover, input[type="submit"]:hover, input[type="reset"]:hover, input[type="button"]:hover {
  color: #ccc;
  border-color: #fff;
  outline: 0;
}

.button:active, button:active, input[type="submit"]:active, input[type="reset"]:active, input[type="button"]:active {
  color: #333;
  background-color: #ddd;
  border-color: #888;
  outline: 0;
}

.button.button-primary, button.button-primary, input[type="submit"].button-primary, input[type="reset"].button-primary, input[type="button"].button-primary {
  color: #fff;
  background-color: #188fb4;
  border-color: #188fb4;
}

.button.button-primary:hover, button.button-primary:hover, input[type="submit"].button-primary:hover, input[type="reset"].button-primary:hover, input[type="button"].button-primary:hover {
  color: #fff;
  background-color: #1eaedb;
  border-color: #188fb4;
}

.button.button-primary:active, button.button-primary:active, input[type="submit"].button-primary:active, input[type="reset"].button-primary:active, input[type="button"].button-primary:active {
  color: #fff;
  background-color: #33c3f0;
  border-color: #0c485a;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea, select {
  color: #000;
  box-shadow: none;
  box-sizing: border-box;
  background-color: #aaa;
  border: 1px solid #000;
  border-radius: 4px;
  height: 38px;
  padding: 6px 10px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
  appearance: none;
}

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="text"]:focus, input[type="tel"]:focus, input[type="url"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
  border: 1px solid #188fb4;
  outline: 0;
}

label, legend {
  margin-bottom: .5rem;
  font-weight: 600;
  display: block;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  display: inline;
}

label > .label-body {
  margin-left: .5rem;
  font-weight: normal;
  display: inline-block;
}

code {
  white-space: nowrap;
  background: #1a1a1a;
  border: 1px solid #1f1f1f;
  border-radius: 4px;
  margin: 0 .2rem;
  padding: .2rem .5rem;
  font-size: 90%;
}

pre > code {
  white-space: pre;
  padding: 1rem 1.5rem;
  display: block;
}

th, td {
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
  padding: 12px 15px;
}

th:first-child, td:first-child {
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}

hr {
  border-width: 1px 0 0;
  border-top-style: solid;
  border-top-color: #0f0f0f;
  margin-top: 3rem;
  margin-bottom: 3.5rem;
}

img {
  opacity: .75;
}

/*# sourceMappingURL=index.8fde8fad.css.map */
